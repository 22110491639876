import {CircularProgress} from '@mui/material';
import {ReactElement, useEffect} from 'react';

import {Container, Loading} from './styled';
import {useLocation, useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import jwtDecode from "jwt-decode";

const Callback = (): ReactElement => {
  const {hash} = useLocation();
  const [, setToken] = useCookies(['token']);
  const navigate = useNavigate();

  useEffect(() => {
    if (hash.startsWith('#') && hash.includes('id_token=')) {
      const hashObject: { [key: string]: string; } = {};
      hash.substring(1).split('&').forEach((keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        hashObject[key] = value;
      })

      const token = hashObject.id_token;
      if (token) {
        try {
          const {exp} = jwtDecode(token) as any;

          const expiresIn = exp - Math.floor(Date.now() / 1000);
          const expirationDate = new Date(Date.now() + expiresIn * 1000);

          setToken('token', token, {expires: expirationDate, sameSite: true});
          navigate('/');
        } catch (e) {
          window.location.href = process.env.REACT_APP_LOGIN_URL || 'login'
        }
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL || 'login'
      }
    }
  }, [hash, navigate, setToken]);

  return (
    <Container>
      <Loading>
        <CircularProgress/>
      </Loading>
    </Container>
  );
};

export default Callback;
