import { Grid } from '@mui/material';
import { FetchCodesForCategory } from '../FetchCodesForCategory';
import {Category, ProcedureCode} from '../../../state/types';
import AccordionRow from '../../../../sharedComponents/AccordionRow/AccordionRow';

type SearchResultsProps = {
  procedureCodeCategories: Category[];
  isMobileDevice: boolean;
  toggleModal: () => void;
  onRowClick  : (code: ProcedureCode) => void;
};

const SearchResults = ({
  procedureCodeCategories,
  isMobileDevice,
  toggleModal,
  onRowClick,
}: SearchResultsProps) => {
  return (
    <Grid container spacing={isMobileDevice ? 1 : 2}>
      {procedureCodeCategories.map((category) => (
        <AccordionRow
          key={category.uuid}
          category={category}
          elevation={0}
          shouldStartOpen={procedureCodeCategories.length <= 1}
          shouldAddElevation
        >
          <Grid container>
            {category.childrenCategories.map((cat2: any) => (
              <AccordionRow
                key={cat2.uuid}
                category={cat2}
                elevation={0}
                shouldStartOpen={category.childrenCategories.length <= 1}
              >
                <Grid container>
                  {cat2.childrenCategories.map((cat3: any) => (
                    <AccordionRow
                      key={cat3.uuid}
                      elevation={0}
                      category={cat3}
                      shouldStartOpen={cat2.childrenCategories.length <= 1}
                    >
                      <Grid item>
                        <FetchCodesForCategory
                          procedureCode={cat3}
                          toggleModal={toggleModal}
                          onRowClick={onRowClick}
                        />
                      </Grid>
                    </AccordionRow>
                  ))}
                </Grid>
              </AccordionRow>
            ))}
          </Grid>
        </AccordionRow>
      ))}
    </Grid>
  );
};

export default SearchResults;
