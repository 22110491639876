export const sortByProperty = (a: any, b: any, propertyName: string) => {
  const propertyA =
    typeof a[propertyName] === 'string'
      ? a[propertyName].toUpperCase()
      : a[propertyName]; // ignore upper and lowercase
  const propertyB =
    typeof b[propertyName] === 'string'
      ? b[propertyName].toUpperCase()
      : b[propertyName]; // ignore upper and lowercase
  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }

  // properties must be equal
  return 0;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
