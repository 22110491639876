import { AppBar, Toolbar } from '@mui/material';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

export const AppBarSC = styled(AppBar)`
  && {
    box-shadow: none;
    /* height: 64px; */
    z-index: 1201;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const ToolbarSC = styled(Toolbar)`
  color: white;
  display: flex;
  justify-content: space-between;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const Logo = styled.div`
  color: white;
  font-weight: 500;
  padding: 0rem 1.5rem;
`;

export const Image = styled.img<any>`
  height: 50px;
  margin-top: 5px;
  background: ${props => props.isInstitution ? props.theme.colors.primary : props.theme.colors.white};
`;

export const UserDetails = styled.div`
  /* width: 150px; */
  /* padding: 5px; */
  /* margin-left: 5px; */
  /* margin-right: 15px; */
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
  color: white;
  /* border-radius: 4px; */
`;

export const FullName = styled.div`
  font-size: 14px;
`;

export const NavTitle = styled.div`
  && {
    font-family: Roboto;
    font-size: 1.5rem;
    letter-spacing: 0.25px;
    line-height: 24px;
    /* padding-left: 2rem; */
  }
`;

export const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
`;

export const SidebarToggle = styled(MenuIcon)`
  color: white;
`;
