import {Box} from '@mui/material';
import {useCallback, useState} from 'react';
import NavigationProfile from '../NavigationProfile/NavigationProfile';
import {ButtonOutlineSC, MenuSC, NavigationDivider, PaperSC, StyledPermIdentityIcon,} from './styled';

type NavigationLogoutProps = {
  user: {
    firstName: string;
    lastName: string;
    error?: any;
    loading?: boolean;
  };
  onLogout: () => void;
};

const NavigationLogout = ({user, onLogout}: NavigationLogoutProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    (event: any) => setAnchorEl(event?.currentTarget || null),
    [setAnchorEl]
  );

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return (
    <div>
      <Box
        onClick={handleClick}
        aria-haspopup="true"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-controls="simple-menu"
      >
        <StyledPermIdentityIcon/>
      </Box>
      <MenuSC
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <PaperSC>
          <NavigationProfile handleClose={handleClose} user={user}/>
          <NavigationDivider/>
          <ButtonOutlineSC onClick={() => onLogout()}>LOGOUT</ButtonOutlineSC>
        </PaperSC>
      </MenuSC>
    </div>
  );
};

export default NavigationLogout;
