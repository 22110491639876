import styled from 'styled-components';
import { Accordion, ListItemIcon } from '@mui/material';

export const DrawerHeaderTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text.medium};
`;

export const StyledExpansionPanel = styled(Accordion)`
  && {
    box-shadow: none;
    margin-top: 0;
    ::before {
      background-color: inherit;
    }
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  && {
    margin-right: 0px;
    min-width: 36px;
  }
`;

export const ClosedDrawerIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SchoolHeaderLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
