import { TextField } from '@mui/material';
import styled from 'styled-components';
import AccordionRow from '../../sharedComponents/AccordionRow/AccordionRow';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem;
`;

export const StyledSearchBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const StyledSearchBox = styled.input`
  && {
    height: 52px;
    min-height: 52px;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.black[24]};
    border-radius: 4px;
    padding-left: 1rem;
    font-family: 'IBM Plex Sans';
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding-right: 2.2rem;
    &::placeholder {
      height: 24px;
      width: 268px;
      color: ${props => props.theme.colors.black[54]};
      font-family: 'IBM Plex Sans';
      font-size: 16px;
      letter-spacing: 0.15px;
      line-height: 24px;

      margin-top: 1rem;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledSearchIconContainer = styled.button`
  && {
    position: absolute;
    background-color: ${props => props.theme.colors.primary};
    right: 0;
    top: 0.9rem;
    height: 3.25rem;
    width: 3.25rem;
    margin-top: -0.9rem;
    padding: 0.25rem 0 0 0.1rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &:focus {
      outline: 0;
    }
    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export const StyledClearIconContainer = styled.button`
  && {
    position: absolute;
    right: 3.25rem;
    top: 1rem;
    height: 3rem;
    background: ${props => props.theme.colors.white};
    border: none;
    width: 2rem;
    margin-top: -0.9rem;

    padding: 0.5rem 0 0 0.1rem;
    &:focus {
      outline: 0;
    }
    & div {
      margin-left: ${({ loading }: { loading?: boolean }) =>
        loading ? '-1rem' : '0'};
      margin-top: ${({ loading }: { loading?: boolean }) =>
        loading ? '-0.25rem' : '0'};
    }

    svg {
      height: ${({ loading }: { loading?: boolean }) =>
        loading ? '40px' : '20px'};
      fill: ${props => props.theme.colors.black[54]};
    }
  }
`;

export const StyledFilterTextField = styled(TextField)`
  display: flex;
  flex: 0.5;
`;

export const Spacer = styled.div`
  width: ${({ width }: { width?: string }) => width || '30px'};
`;

export const ProcedureCodeCategoryAccordion = styled(
  ({ withBottomBorder, ...rest }) => <AccordionRow {...rest} />
)`
  border-bottom: ${(props) =>
    props.withBottomBorder ? `1px solid ${props.theme.colors.border}` : 'none'};
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
