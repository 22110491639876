import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Link, useLocation } from 'react-router-dom';

import { Feature, FeatureIcon, FeatureLabel } from './styled';

import { userState } from '../../state';
import { useBreakPoint } from '../../../../helpers/hooks/useBreakPoint';

type SchoolFeatureProps = {
  feature: {
    featureId: string;
    url: string;
    onClick: () => void;
    icon: any;
    featureName: string;
  };
  drawerOpen: boolean;

  schoolUUID: string;
};

const SchoolFeature = ({
  feature,
  schoolUUID,
  drawerOpen,
}: SchoolFeatureProps) => {
  const { pathname } = useLocation();
  const { isMobile, isTablet } = useBreakPoint();
  const [user] = useAtom(userState);

  const activeFeature = useMemo(
    () =>
      feature.featureId === user.selectedFeatureUuid ||
      pathname === feature.url,
    [feature.featureId, feature.url, pathname, user.selectedFeatureUuid]
  );

  return (
    <Link
      key={`${feature.featureId}`}
      to={feature.url}
      onClick={feature.onClick}
    >
      <Feature
        open={drawerOpen}
        selected={activeFeature}
        isLevel2={isMobile || isTablet}
      >
        <FeatureIcon>{feature.icon}</FeatureIcon>
        <FeatureLabel drawerOpen={drawerOpen}>
          {drawerOpen && feature.featureName}
        </FeatureLabel>
      </Feature>
    </Link>
  );
};

export default SchoolFeature;
