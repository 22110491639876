import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useBreakPoint } from '../../../helpers/hooks/useBreakPoint';
import { SearchResultsContainer } from '../components/SearchResultsContainer';
import { LoadingContainer } from './styled';
import { SearchResults } from './components/SearchResults';
import ClearAll from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import fetch from '../../../helpers/fetch';
import useSWR from 'swr';
import CodeModal from '../components/CodeModal/CodeModal';
import {
  Category,
  ProcedureCode,
  SearchByCode as SearchByCodeType,
} from '../state/types';

const Loading = () => (
  <SearchResultsContainer>
    <LoadingContainer>
      <CircularProgress size={50} />
    </LoadingContainer>
  </SearchResultsContainer>
);

const SearchByCategory = () => {
  const { isMobile, isTablet } = useBreakPoint();
  const params = useParams();

  const [term, setTerm] = useState('');
  const [selectedCode, setSelectedCode] = useState<SearchByCodeType | null>(
    null,
  );
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading } = useSWR(
    `/api/search/by-categories/${params.schoolUuid}`,
    (url) => fetch(url).then((res) => res.json()),
  );

  const filterItems = useCallback(
    (cat: Category[], term: string): Category[] => {
      let matches = [] as Category[];
      if (!Array.isArray(cat)) return matches;

      cat.forEach((category) => {
        if (
          category.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
        ) {
          matches.push(category);
        } else {
          let childResults = filterItems(category.childrenCategories, term);
          if (childResults.length)
            matches.push(
              Object.assign({}, category, { childrenCategories: childResults }),
            );
        }
      });

      return matches;
    },
    [],
  );

  useEffect(() => {
    if (data?.procedureCodeCategories) {
      setFilteredCategories(data.procedureCodeCategories || []);
    }
  }, [data?.procedureCodeCategories]);

  if (isLoading) return <Loading />;

  return (
    <SearchResultsContainer>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <TextField
            id="description"
            sx={{ width: '100%' }}
            label="Search by category"
            variant="outlined"
            value={term}
            onChange={(e) => {
              const filtered = filterItems(
                data?.procedureCodeCategories || [],
                e.target.value,
              );
              setFilteredCategories(filtered);
              setTerm(e.target.value);
            }}
            onKeyDown={(e) => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                >
                  {term.length > 1 && (
                    <IconButton
                      aria-label="clear search term"
                      onClick={() => {
                        setTerm('');
                      }}
                      edge="end"
                    >
                      <ClearAll />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="search by description code"
                    onClick={() => {}}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {selectedCode && (
          <CodeModal
            modalOpen={isModalOpen}
            toggleModal={() => {
              setIsModalOpen(!isModalOpen);
            }}
            codeUuid={selectedCode.uuid}
          />
        )}

        <Grid item xs={12} md={12}>
          <SearchResults
            procedureCodeCategories={filteredCategories}
            isMobileDevice={isMobile || isTablet}
            toggleModal={() => {
              setIsModalOpen(!isModalOpen);
            }}
            onRowClick={(code: ProcedureCode) => {
              setSelectedCode(code);
            }}
          />
        </Grid>
      </Grid>
    </SearchResultsContainer>
  );
};

export default SearchByCategory;
