import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

type DrawerBodyProps = {
  open?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
};
export const DrawerBody = muiStyled('div', {
  shouldForwardProp: (prop: string) =>
    prop !== 'open' && prop !== 'fullWidth' && prop !== 'loading',
})<DrawerBodyProps>(({ open, fullWidth, loading }) => ({
  marginTop: '64px',
  ...(fullWidth
    ? { width: '100%', height: '100%' }
    : {
        width: open ? '300px' : '60px',
        height: loading ? '100%' : 'auto',
      }),

  '&:focus': {
    outline: 'none',
  },
}));

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
