import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
} from '@mui/material';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const Accordion = muiStyled(
  (props: AccordionProps & { shouldAddElevation?: boolean }) => (
    <MuiAccordion
      elevation={props.shouldAddElevation ? 2 : 0}
      {...props}
      TransitionProps={{ unmountOnExit: true }}
    />
  ),
  {
    shouldForwardProp: (prop: string) => prop !== 'shouldAddElevation',
  }
)(() => ({
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))((props) => ({
  background: '#ccc',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '1rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: `${props.theme.spacing(1)} 0`,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: `${props.theme.spacing(1)} 0`,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)((props) => ({
  padding: props.theme.spacing(5),
}));
