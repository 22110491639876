import { atom } from 'jotai';

export const navigationState = atom<{
  drawerOpen: boolean;
}>({ drawerOpen: true });

export type Institution = {
  uuid: string;
  name: string;
  status: string;
  logo?: string;
  primaryColor?: string;
  secondaryColor?: string;
};
export type Institutions = Institution[];
export type Schools = Institution[];
const DEFAULT_PRIMARY_COLOR = '#00939f';
const DEFAULT_SECONDARY_COLOR = '#9F0C00';

export type UserState = {
  uuid: string;
  firstName: string;
  lastName: string;
  institutions: Institutions;
  schools: Schools;
  error: any;
  loading: boolean;
  institutionUuid?: string;
  institutionLogo?: string;
  primaryColor: string;
  secondaryColor: string;
  institution?: string;
  selectedSchoolId?: string;
  selectedSchool?: string;
  selectedFeature?: string;
  selectedFeatureUuid?: string;
};

const initialUserState: UserState = {
  uuid: '',
  firstName: '',
  lastName: '',
  institutions: [],
  schools: [],
  error: null,
  loading: true,
  institutionUuid: '',
  institution: '',
  selectedSchoolId: '',
  selectedSchool: '',
  selectedFeature: '',
  selectedFeatureUuid: '',
  institutionLogo: '',
  primaryColor: DEFAULT_PRIMARY_COLOR,
  secondaryColor: DEFAULT_SECONDARY_COLOR
};

export const userState = atom(initialUserState);
