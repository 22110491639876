import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
`

const Message = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
`

type UserNotFoundError = {
};

const UserNotFoundError = ({
}: UserNotFoundError) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <NotFoundIllustration
        dotsColor={themeContext.colors.primaryShades[450]}
        bgColor={themeContext.colors.primaryShades[200]}
        outlineColor={themeContext.colors.primary}
      />
      <Message>User not found. Please try to login again or try to contact your administrator.</Message>
    </Container>
  );
};

export default UserNotFoundError;
