import { Fragment, useState } from 'react';
import { SearchResultsContainer } from '../components/SearchResultsContainer';
import {
  Loading,
  NoAssessmentsIllustratorDiv,
  NoAssessmentsMessageContainer,
} from './styled';
import { Box } from '@mui/system';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearAll from '@mui/icons-material/Clear';

import fetch from '../../../helpers/fetch';
import useSWRMutation from 'swr/mutation';
import { useParams } from 'react-router-dom';
import { useBreakPoint } from '../../../helpers/hooks/useBreakPoint';
import Divider from '@mui/material/Divider';
import { MousePointerIllustration } from '../../../helpers/svgIcons';
import { DisplayProcedureCodesBy } from '../components/DisplayProcedureCodesBy';
import { parseSearchResults } from '../hooks/useSearchByHelpers';
import { SearchByCode } from '../state/types';
import CodeModal from '../components/CodeModal/CodeModal';

const SearchByDescription = () => {
  const { isDesktop } = useBreakPoint();

  const params = useParams();

  const [open, setOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [type, setType] = useState<{
    value: string;
    label: string;
  } | null>({ value: 'task', label: 'Task' });
  const [selectedCode, setSelectedCode] = useState<SearchByCode | null>(null);
  const [dropDownSelection, setDropDownSelection] = useState('');
  const [term, setTerm] = useState('');

  const { data, isMutating, trigger } = useSWRMutation(
    '/api/procedure-code/description-codes',
    (url) => fetch(url).then((res) => res.json()),
  );

  const {
    data: searchResults,
    trigger: triggerSearchByDescription,
    isMutating: searchIsMutating,
  } = useSWRMutation('/api/search/by-description', async (url, { arg }) => {
    let searchTerm = '';

    const { search, ddSelection } = arg as {
      search: string;
      ddSelection: string;
    };

    if (search && !ddSelection) {
      searchTerm = search;
    }
    if (!search && ddSelection) {
      searchTerm = ddSelection;
    }
    if (search && ddSelection) {
      searchTerm = `${ddSelection} - %${search}`;
    }

    const res = await fetch(
      `${url}/${params.schoolUuid}/${encodeURIComponent(searchTerm)}`,
    );
    const searchRes = (await res.json()) as {
      procedureCodes: SearchByCode[];
    };

    return parseSearchResults(searchRes.procedureCodes);
  });

  return (
    <SearchResultsContainer>
      <>
        <Box
          display="flex"
          flexDirection={isDesktop ? 'row' : 'column'}
          width="100%"
          gap={isDesktop ? 10 : 3}
        >
          <TextField
            id="description"
            sx={{ width: '100%' }}
            label="Search by description"
            variant="outlined"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                triggerSearchByDescription({
                  // @ts-ignore
                  search: e?.target?.value as string,
                  ddSelection: dropDownSelection,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                >
                  {term.length > 1 && (
                    <IconButton
                      aria-label="clear search term"
                      onClick={() => {
                        setTerm('');
                      }}
                      edge="end"
                    >
                      <ClearAll />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="search by description code"
                    onClick={() => {
                      // @ts-ignore
                      triggerSearchByDescription({
                        search: term,
                        ddSelection: dropDownSelection,
                      });
                    }}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            id="description-codes"
            sx={{ width: '100%' }}
            open={open}
            onOpen={() => {
              trigger();
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option === value}
            options={data?.descriptionCodes || []}
            onChange={(e, value) => {
              setDropDownSelection(value as string);
              // @ts-ignore
              triggerSearchByDescription({
                // @ts-ignore
                search: term,
                ddSelection: value as string,
              });
            }}
            loading={isMutating}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by description code"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isMutating ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            sx={{ width: '100%' }}
            options={[
              {
                label: 'Appointment',
                value: 'appointment',
              },
              {
                label: 'Task',
                value: 'task',
              },
            ]}
            value={type}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, newValue) => {
              setType(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Type" />}
          />
        </Box>

        <Box marginY={4}>
          <Divider />
        </Box>
        {searchIsMutating ? (
          <Loading>
            <CircularProgress size={50} />
          </Loading>
        ) : (
          !searchResults?.length && (
            <NoAssessmentsMessageContainer>
              <NoAssessmentsIllustratorDiv>
                <MousePointerIllustration />
                <div>Please use the above search field</div>
              </NoAssessmentsIllustratorDiv>
            </NoAssessmentsMessageContainer>
          )
        )}
        {detailsModalOpen && selectedCode && (
          <CodeModal
            modalOpen={detailsModalOpen}
            toggleModal={() => {
              setDetailsModalOpen(!detailsModalOpen);
            }}
            codeUuid={selectedCode.uuid}
          />
        )}

        {!searchIsMutating && searchResults && (
          <DisplayProcedureCodesBy
            type={type?.value || 'task'}
            codes={searchResults || []}
            onRowClick={(code) => {
              setSelectedCode(code);
            }}
            toggleModal={() => setDetailsModalOpen(!detailsModalOpen)}
          />
        )}
      </>
    </SearchResultsContainer>
  );
};

export default SearchByDescription;
