import fetch from "../helpers/fetch";
import { UserState } from '../components/Navigation/state';

export const fetchSchools = async (): Promise<UserState> => {
  const response = await fetch(`/api/userschools`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (response.status === 200) {
    const { user } = await response.json();
    return user;
  }

  throw new Error(`fetch failed - status code: ${response.status}`);
};
