import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { Institution, navigationState, userState } from '../state';
import { fetchSchools } from '../../../api/schoolApi';
import { useBreakPoint } from '../../../helpers/hooks/useBreakPoint';
import { useCookies } from 'react-cookie';

let SHOULD_FETCH_SCHOOLS = true;

export const useNavigationState = () => {
  const [{ drawerOpen }, setNavigationState] = useAtom(navigationState);
  const [user, setUser] = useAtom(userState);
  const { isMobile, isTablet } = useBreakPoint();
  const [, , removeToken] = useCookies(['token']);

  const onSetSchool = useCallback(
    (school: Institution) =>
      setUser({
        ...user,
        selectedSchoolId: school.uuid,
        selectedSchool: school.name,
      }),
    [setUser, user],
  );

  const onSetSchoolFeature = useCallback(
    (school: Institution, feature: string, featureId: string) => {
      setUser({
        ...user,
        selectedSchoolId: school.uuid,
        selectedSchool: school.name,
        selectedFeature: feature,
        selectedFeatureUuid: featureId,
      });
    },
    [setUser, user],
  );

  const onFetchSchools = useCallback(() => {
    fetchSchools()
      .then((usr) =>
        setUser({
          ...user,
          ...usr,
          institution: usr.institutions[0].name,
          institutionUuid: usr.institutions[0].uuid,
          institutionLogo: usr.institutions[0].logo,
          primaryColor: usr.institutions[0].primaryColor || user.primaryColor,
          secondaryColor:
            usr.institutions[0].secondaryColor || user.secondaryColor,
          loading: false,
        }),
      )
      .catch((error) =>
        setUser({ ...user, error: error.message, loading: false }),
      );
  }, [setUser, user]);

  const handleToggleDrawer = useCallback(() => {
    setNavigationState({ drawerOpen: !drawerOpen });
  }, [drawerOpen, setNavigationState]);

  const handleLogout = useCallback(() => {
    if (process.env.REACT_APP_USE_IBM_CLOUD === 'true') {
      window.location.assign(
        `${window.location.protocol}//${window.location.hostname}/appid_logout`,
      );
    } else {
      removeToken('token');
      window.location.href = (
        process.env.REACT_APP_LOGIN_URL || 'login'
      ).replace('/oauth2/authorize?', '/logout?');
    }
  }, [removeToken]);

  useEffect(() => {
    if (isMobile || isTablet) setNavigationState({ drawerOpen: false });
    else setNavigationState({ drawerOpen: true });
  }, [isMobile, isTablet, setNavigationState]);

  useEffect(() => {
    if (SHOULD_FETCH_SCHOOLS) {
      onFetchSchools();
      SHOULD_FETCH_SCHOOLS = false;
    }
  }, [onFetchSchools]);

  return {
    drawerOpen,
    user,
    onSetSchool,
    onSetSchoolFeature,
    handleToggleDrawer,
    handleLogout,
  };
};
