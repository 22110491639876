import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { muiTheme } from '../sharedComponents/theme';

import Navigation from '../Navigation/Navigation';
import { routes } from './routes';
import './App.css';

import { Welcome } from '../sharedComponents/Welcome';
import { Callback } from "../sharedComponents/Callback";
import { Box, Typography } from '@mui/material';
import { userState } from '../Navigation/state';
import UserNotFoundError from '../Navigation/components/UserNotFoundError/UserNotFoundError';

const App = () => {
  const [user] = useAtom(userState);
  const muiThemeObject = muiTheme(user.primaryColor, user.secondaryColor);

  return (
    <MuiThemeProvider theme={muiThemeObject}>
      <ThemeProvider theme={muiThemeObject}>
        {/* <BodySC> */}
        <Router>
          {/* <RouterChild> */}
          <Navigation />
          {user.error 
            ? 
              <UserNotFoundError />
            : 
              <Routes>
                {routes.map((route, i) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                  />
                ))}
                <Route
                  path="*"
                  element={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                      }}
                    >
                      <Typography variant="h3">Not Found</Typography>
                    </Box>
                  }
                />
                <Route path="/callback" element={<Callback />} />
                <Route path="/" element={<Welcome />} />
              </Routes>
          }
          {/* </RouterChild> */}
        </Router>
        {/* </BodySC> */}
      </ThemeProvider>
    </MuiThemeProvider>
  )
};

export default App;
