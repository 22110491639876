import { ProcedureCode } from '../../../state/types';

import { Box, CircularProgress } from '@mui/material';
import { Caption } from '@xcomp/xcomp-design-library';
import { DisplayCode } from '../../../components/DisplayCode';
import useSWR from 'swr';

type FetchCodesForCategoryProps = {
  procedureCode: ProcedureCode;
  toggleModal?: () => void;
  onRowClick: (uuid: ProcedureCode) => void;
};

const FetchCodesForCategory = ({
  procedureCode,
  toggleModal,
  onRowClick,
}: FetchCodesForCategoryProps) => {
  const { data, isLoading } = useSWR(
    procedureCode?.uuid
      ? `/api/procedure-code/category/${procedureCode.uuid}/codes`
      : null,
    (url) => fetch(url).then((res) => res.json()),
  );

  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {data?.procedureCodes?.length === 0 ? (
        <Caption>No procedure codes found</Caption>
      ) : (
        data?.procedureCodes?.map((code: ProcedureCode, index: number) => (
          <DisplayCode
            code={code.code}
            description={code.description}
            isArchived={code.is_archived}
            key={code.uuid}
            onRowClick={() => {
              onRowClick(code);
              toggleModal && toggleModal();
            }}
          />
        ))
      )}
    </Box>
  );
};

export default FetchCodesForCategory;
