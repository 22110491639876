import fetch from '../helpers/fetch';

export const fetchMicrocompetencyDetails = async (
  uuid: string,
): Promise<any> => {
  const response = await fetch(`/api/microcompetency/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (response.status === 200) {
    const asd = await response.json();
    return asd;
  }

  throw new Error(`fetch failed - status code: ${response.status}`);
};
