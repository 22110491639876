import { styled as muiStyled } from '@mui/material/styles';

export const Main = muiStyled('main', {
  shouldForwardProp: (prop: string) => prop !== 'isMobile' && prop !== 'open',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => {
  return {
    flexGrow: 1,
    padding: isMobile ? 0 : theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: isMobile ? '58px' : '64px',
    marginLeft: isMobile ? 0 : '64px',
    ...(open &&
      !isMobile && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: '300px',
      }),
  };
});
