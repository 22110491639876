import styled from 'styled-components';

export const NoAssessmentsMessageContainer = styled.div`
  && {
    height: 368px;
    text-align: center;
  }
`;

export const NoAssessmentsIllustratorDiv = styled.div`
  && {
    padding-top: 3.75rem;
    padding-bottom: 2rem;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 270px;
`;
