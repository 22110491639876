import {createTheme} from '@mui/material/styles';
import {grey, red} from '@mui/material/colors';
import hexToRgba from 'hex-to-rgba';
import {hexToRgb} from '../../helpers/utilities';

const rgbToHex = (r: any, g: any, b: any) => [r, g, b].map(x => {
  const hex = x.toString(16);
  return hex.length === 1 ? `0${hex}` : hex
}).join('');

const shadeColor = (hexColor: any, percentage: any) => {
  const rgb = hexToRgb(hexColor);

  if (!rgb) {
    return '';
  }

  const tintR = Math.round(rgb.r - (rgb.r * percentage));
  const tintG = Math.round(rgb.g - (rgb.g * percentage));
  const tintB = Math.round(rgb.b - (rgb.b * percentage));

  return `#${rgbToHex(tintR, tintG, tintB)}`;
}

const tintColor = (hexColor: any, percentage: any) => {
  const rgb = hexToRgb(hexColor);

  if (!rgb) {
    return '';
  }

  const tintR = Math.round(rgb.r + ((255 - rgb.r) * percentage));
  const tintG = Math.round(rgb.g + ((255 - rgb.g) * percentage));
  const tintB = Math.round(rgb.b + ((255 - rgb.b) * percentage));

  return `#${rgbToHex(tintR, tintG, tintB)}`;
}

const createShadesFromColor = (color: any) => {
  return ({
    50: tintColor(color, 0.9),
    100: tintColor(color, 0.8),
    150: tintColor(color, 0.7),
    200: tintColor(color, 0.6),
    250: tintColor(color, 0.5),
    300: tintColor(color, 0.4),
    350: tintColor(color, 0.3),
    400: tintColor(color, 0.2),
    450: tintColor(color, 0.1),
    500: color,
    550: shadeColor(color, 0.1),
    600: shadeColor(color, 0.2),
    650: shadeColor(color, 0.3),
    700: shadeColor(color, 0.4),
    750: shadeColor(color, 0.5),
    800: shadeColor(color, 0.6),
    850: shadeColor(color, 0.7),
    900: shadeColor(color, 0.8),
    950: shadeColor(color, 0.9),
  })
}

const black = {
  100: '#000000',
  87: '#212121',
  54: '#757575',
  38: '#9E9E9E',
  24: '#C2C2C2',
  12: '#E0E0E0',
};

const coreBlue = {
  100: '#005378',
  87: '#216989',
  54: '#75A2B6',
  38: '#9EBECC',
  24: '#C2D6DF',
  overlay: '#F0F5F7',
};

const status = {
  grey: '#9E9E9E',
  red: '#D32F2F',
  amber: '#FFA000',
  green: '#388E3C',
};

const sourceTeal = {
  100: '#00929B',
  87: '#00929B',
  54: '#75C4C9',
  38: '#9ED5D9',
  24: '#C2E5E7',
  overlay: '#F0F9F9',
};

const createColors = (primary: string, secondary: string) => ({
  primary,
  secondary,
  primaryShades: createShadesFromColor(primary),
  secondaryShades: createShadesFromColor(secondary),
  background: hexToRgba(black[12], 0.12),
  border: black[12],
  success: '#388E3C',
  error: '#D32F2F',
  errorBg: '#F9E6E6',
  info: '#42A5F5',
  syllabus: {
    encounter: '#42A5F5',
    assessment: '#EF5350',
  },
  text: {
    primary: black[87],
    secondary: black[54],
  },
  black,
  white: '#FFFFFF',
  coreBlue,
  sourceTeal,
});

export const theme = (primary: string, secondary: string) => {
  const colors = createColors(primary, secondary);
  return ({
    colors,
    fonts: {
      primary: 'IBM Plex Sans',
      secondary: 'IBM Plex Sans',
    },
    boxShadows: {
      low: '0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)',
      medium:
        '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
      high: '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)',
      overlay:
        '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
    },
    accordion: {
      column: {
        guide: `2px solid ${grey[100]}`,
      },
      hoverBg: hexToRgba(colors.primaryShades[50], 0.8),
    },
    buttons: {
      primary: {
        background: primary,
        color: '#fff',
      },
      inline: {
        color: primary,
        hoverBackground: hexToRgba(colors.primaryShades[50], 1),
        hoverColor: primary,
      },
      outline: {
        color: primary,
        border: grey[300],
      },
      danger: {
        color: 'white',
        background: red[500],
      },
    },
    icons: {
      default: grey[600],
      material: grey[500],
      active: grey[900],
      disabled: grey[300],
    },
    disabled: {
      hoverBackground: grey[100],
      textColorLight: grey[300],
      textColorDark: grey[500],
    },

    syllabus: {
      encounterColor: colors.syllabus.encounter,
      assessmentColor: colors.syllabus.assessment,
    },
    navigation: {
      hover: grey[100],
    },
    // added for rbaas
    spacing: (value: number) => `${value * 5}px`,
  })
};

export const muiTheme = (
  primary: string,
  secondary: string,
) => {
  const primaryHover = hexToRgb(primary);
  const primaryHoverString = secondary
    ? `rgba(${primaryHover?.r}, ${primaryHover?.g}, ${primaryHover?.b}, 0.1)`
    : grey[100];

  const themeObject = theme(primary, secondary);

  return createTheme({
    ...themeObject,
    typography: {
      fontFamily: 'IBM Plex Sans',
    },
    palette: {
      primary: {
        light: themeObject.colors.primaryShades[300],
        main: themeObject.colors.primary,
        dark: themeObject.colors.primaryShades[700],
      },
      secondary: {
        light: themeObject.colors.secondaryShades[300],
        main: themeObject.colors.secondary,
        dark: themeObject.colors.secondaryShades[700],
      },
      error: {
        main: status.red,
      },
      action: {
        hover: primaryHoverString,
      },
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            'font-size': '12px',
            'padding-top': '10px',
            'padding-bottom': '10px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingRight: 0,
            },
          },
          head: {
            'font-size': '12px',
            'font-weight': '500',
            'letter-spacing': '0.25px',
            'line-height': '20px',
            color: '#757575',
          },
          body: {
            'font-size': '16px',
            'font-weight': '500',
            'letter-spacing': '0.15px',
            'line-height': '24px',
            color: '#212121',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            'font-size': '.8rem',
          },
        },
      },
    },
  });
};
