import styled from 'styled-components';
import { Subtitle2, Caption, Body1 } from '@xcomp/xcomp-design-library';

export const ModalBodySC = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-bottom: 0.5rem;
  }
`;

export const ModalButtonsSC = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const FormSectionHeaderSubtitle = styled(Subtitle2)`
  && {
    color: ${(props) => props.theme.colors.text.primary};
    position: relative;
    display: inline-block;
    z-index: 100;
    background: ${(props) => props.theme.colors.white};
    padding-right: 0.5rem;
  }
`;

export const FormSectionHeader = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.border};
  }
`;

type ColumnnProps = {
  size: any;
  paddingRight: string;
  paddingLeft: string;
  hasIcon: boolean;
  [key: string]: any;
};

export const Column = styled(
  ({ size, paddingRight, paddingLeft, hasIcon, ...rest }: ColumnnProps) => (
    <div {...rest} />
  )
)`
  width: ${({ size }) => size};
  font-size: inherit;
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  svg {
    color: ${(props) => props.hasIcon && props.theme.icons.default};
  }
  &.italic {
    font-style: italic;
  }
`;

export const AssociatedRubricSection = styled(Column)`
  margin-top: 2rem;
`;

export const CaptionSC = styled(Caption)`
  margin-bottom: 0;
`;

export const Body1SC = styled(Body1)`
  margin-top: 0;
`;

export const AssociatedRubricLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0.9375rem;

  p {
    margin-bottom: 0;
  }

  button {
    margin-left: 0.5rem;

    svg {
      font-size: 14px;
    }
  }
`;
