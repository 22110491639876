import { Theme, useMediaQuery } from '@mui/material';

export const useBreakPoint = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('xs', 'sm')
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'md')
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
