import { Badge } from '@mui/material';
import styled from 'styled-components';

import { Body1, Subtitle2, Caption } from '@xcomp/xcomp-design-library';

export const DrawerBody = styled.div`
  margin-top: 64px;
  padding-bottom: 40px;
  width: ${({ open }: { open: boolean }) => (open ? '300px' : '60px')};
  transition-duration: 0.2s;
  &:focus {
    outline: none;
  }
`;

export const StyledEmptyContainer = styled.div``;

export const StyledBadge = styled(Badge)`
  && {
    margin-top: 20px;

    span {
      background-color: ${props => props.theme.colors.primaryShades[150]};
      color: black;
    }
  }
`;

export const ToggleNavSection = styled.div`
  color: ${(props) => props.theme.colors.text.dark};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.navigation.hover};
  }
`;

export const ToggleNavSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

export const SchoolName = styled(Body1)`
  white-space: nowrap;
  color: ${(props) => props.theme.colors.text.dark};
  margin: 0;
  line-height: 1.4;
  &:hover {
    color: ${(props) => props.theme.colors.text.dark};
  }
`;

export const ToggleNavSectionCaption = styled(Caption)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.dark};
  margin: 0;
  line-height: 1.4;
`;

export const Role = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  justify-content: ${({ open }: { open: boolean }) =>
    open ? 'flex-start' : 'center'};
  padding-left: ${({ open }: { open: boolean }) => (open ? '16px' : '0px')};
`;

export const RoleLabel = styled(Subtitle2)`
  white-space: nowrap;
  color: ${(props) => props.theme.colors.text.medium};
`;

export const RoleIcon = styled(Subtitle2)`
  && {
    font-size: 12px;
    color: ${(props) => props.theme.colors.text.medium};
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75px;
  align-items: ${({ open }: { open: boolean }) =>
    open ? 'flex-start' : 'center'};
  color: rgba(0, 0, 0, 0.6);
  justify-content: ${({ open }: { open: boolean }) =>
    open ? 'center' : 'center'};
  padding-left: ${({ open }: { open: boolean }) => (open ? '16px' : '0px')};
`;

export const DrawerHeaderTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.text.medium};
`;

export const DrawerHeaderSubTitle = styled.div`
  white-space: nowrap;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.medium};
`;

export const NavigationDividerLayout = styled.div`
  && {
    margin-top: 6px;
    margin-bottom: 0;
  }
`;
