import { Menu, Paper } from '@mui/material';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';

import { ButtonOutline, ButtonIcon } from '@xcomp/xcomp-design-library';
import { PermIdentity } from '../../../../helpers/svgIcons';

export const PaperSC = styled(Paper)`
  && {
    width: 300px;
    min-width: 300px;
  }
`;

export const MenuSC = styled(Menu)`
  && {
    /* margin-top: 2rem; */
    div ul {
      padding: 0px;
    }
    top: 1rem;
  }
`;

export const ButtonOutlineSC = styled(ButtonOutline)`
  && {
    margin: 1rem 0rem 1rem 2rem;
    &:hover {
      background-color: ${props => props.theme.colors.black[12]};
    }
  }
`;

export const ButtonSC = styled(ButtonIcon)`
  && {
    color: ${props => props.theme.colors.primary};
    svg {
      fill: ${props => props.theme.colors.primary};
    }
    &:hover {
      background: none;
      svg {
        fill: ${props => props.theme.colors.primary};
      }
    }
    &:focus {
      background: none;
      svg {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`;

export const StyledPermIdentityIcon = styled(PermIdentity)`
  margin-top: 6px;
  margin-left: 0.5rem;
`;

export const NavigationDividerLayout = styled.div`
  && {
    margin-top: 6px;
    margin-bottom: 0;
  }
`;

export const NavigationDivider = () => (
  <NavigationDividerLayout>
    <Divider />
  </NavigationDividerLayout>
);
