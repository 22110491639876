import { Code, Description, Category } from '@mui/icons-material';

import SchoolExpansionPanel from '../SchoolExpansionPanel/SchoolExpansionPanel';

import {
  StyledBadge,
  StyledEmptyContainer,
  DrawerHeader,
  DrawerHeaderTitle,
} from './styled';

import { useMemo } from 'react';
import { searchFeatures } from '../../../../helpers/constants';
import { SchoolFeature } from '../SchoolFeature';
import { useBreakPoint } from '../../../../helpers/hooks/useBreakPoint';
import { navigationState } from '../../state';
import { useAtom } from 'jotai';

type SchoolSearchFeatures = {
  featureId: string;
  featureName: string;
  url: string;
  onClick: () => void;
  icon: any;
};

export type SchoolList = {
  uuid: string;
  name: string;
  searchFeatures?: SchoolSearchFeatures[];
};

type Institution = {
  uuid: string;
  name: string;
};

type UserSchoolListProps = {
  drawerOpen: boolean;
  onSetSchoolFeature: (school: any, feature: string, featureId: string) => void;
  schoolList: SchoolList[];
  institution: Institution;
};
const UserSchoolList = ({
  drawerOpen,
  onSetSchoolFeature,
  schoolList,
  institution,
}: UserSchoolListProps) => {
  const [, setNavDrawerOpen] = useAtom(navigationState);
  const { isMobile, isTablet } = useBreakPoint();
  const institutionAbbreviation = useMemo(() => {
    let abbr;
    if (
      institution?.name
        ?.trim()
        .toLowerCase()
        .includes('east carolina university')
    ) {
      abbr = 'ECU';
    } else {
      abbr = 'INST';
    }
    return abbr;
  }, [institution?.name]);

  const schoolListItems = useMemo<SchoolList[]>(
    () =>
      schoolList.map((school) => ({
        ...school,
        searchFeatures: [
          {
            featureId: `${school.uuid}-by-code`,
            featureName: searchFeatures.byCode,
            url: `/school/${school.uuid}/search/by-code`,
            onClick: () => {
              onSetSchoolFeature(
                school,
                'Search by Code',
                `${school.uuid}-by-code`
              );
              (isMobile || isTablet) && setNavDrawerOpen({ drawerOpen: false });
            },
            icon: <Code color="disabled" />,
          },
          {
            featureId: `${school.uuid}-by-category`,
            featureName: searchFeatures.byCategory,
            url: `/school/${school.uuid}/search/by-category`,
            onClick: () => {
              onSetSchoolFeature(
                school,
                'Search by Category',
                `${school.uuid}-by-category`
              );
              (isMobile || isTablet) && setNavDrawerOpen({ drawerOpen: false });
            },
            icon: <Category color="disabled" />,
          },
          {
            featureId: `${school.uuid}-by-description`,
            featureName: searchFeatures.byDescription,
            url: `/school/${school.uuid}/search/by-description`,
            onClick: () => {
              onSetSchoolFeature(
                school,
                'Search by Description',
                `${school.uuid}-by-description`
              );
              (isMobile || isTablet) && setNavDrawerOpen({ drawerOpen: false });
            },
            icon: <Description color="disabled" />,
          },
        ],
      })),
    [isMobile, isTablet, onSetSchoolFeature, schoolList, setNavDrawerOpen]
  );

  return (
    <StyledEmptyContainer>
      {drawerOpen ? (
        <DrawerHeader open={drawerOpen}>
          <DrawerHeaderTitle>{institution?.name}</DrawerHeaderTitle>
        </DrawerHeader>
      ) : (
        <DrawerHeader open={drawerOpen}>
          <DrawerHeaderTitle>{institutionAbbreviation}</DrawerHeaderTitle>
          <StyledBadge badgeContent={schoolList.length} showZero />
        </DrawerHeader>
      )}
      <StyledEmptyContainer>
        {schoolListItems.map((school) => (
          <SchoolExpansionPanel
            key={school.uuid}
            school={school}
            drawerOpen={drawerOpen}
          >
            {school.searchFeatures
              ? school.searchFeatures.map((feature) => (
                  <SchoolFeature
                    key={`${school.uuid}-${feature.featureId}`}
                    feature={feature}
                    schoolUUID={school.uuid}
                    drawerOpen={drawerOpen}
                  />
                ))
              : []}
          </SchoolExpansionPanel>
        ))}
      </StyledEmptyContainer>
    </StyledEmptyContainer>
  );
};

export default UserSchoolList;
