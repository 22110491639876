import { ReactElement } from 'react';
import { useBreakPoint } from '../../../../helpers/hooks/useBreakPoint';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {
  AssociatedRubricLayout,
  AssociatedRubricSection,
  Body1SC,
  CaptionSC,
  FormSectionHeader,
  FormSectionHeaderSubtitle,
} from './styled';
import { Box } from '@mui/system';
import { AccordionRow } from '../../../sharedComponents/AccordionRow/AccordionRow';
import { AssociatedRubricStep, CheckStep } from '../../state/types';
import { sortByProperty } from '../../../../helpers/utilities';
import useSWR from 'swr';
import { fetchMicrocompetencyDetails } from '../../../../api/procedureCodeDetailsApi';

type CodeModalProps = {
  modalOpen: boolean;
  toggleModal: () => void;
  codeUuid?: string;
};

const CodeModal = ({
  modalOpen,
  toggleModal,
  codeUuid,
}: CodeModalProps): ReactElement | null => {
  const { isMobile, isTablet } = useBreakPoint();

  const { data, isLoading } = useSWR(
    `/api/procedure-code/${codeUuid}/details`,
    async (url: string) => {
      const res = await fetch(url).then((res) => res.json());
      const { microcompetency } = await fetchMicrocompetencyDetails(
        res.procedureCodes.associatedRubric.microcompetencyUuid,
      );

      let checkSteps: {
        [uuid: string]: CheckStep[];
      } = {};
      let maxPoints: { [uuid: string]: string } = {};

      res.procedureCodes.steps.forEach((step: any) => {
        checkSteps[step.associatedRubricStepUuid] = step.checksteps.sort(
          (a: any, b: any) => sortByProperty(a, b, 'order'),
        );
        maxPoints[step.associatedRubricStepUuid] = step.maxPoints;
      });

      return {
        ...res.procedureCodes,
        associatedRubric: {
          ...res.procedureCodes.associatedRubric,
          microcompetency,
        },
        checkSteps,
        maxPoints,
      };
    },
  );

  return (
    <Dialog
      open={modalOpen}
      onClose={toggleModal}
      fullScreen={data && (isMobile || isTablet)}
      fullWidth
      scroll="paper"
      PaperProps={{ sx: { maxWidth: '900px' } }}
    >
      <DialogTitle id="responsive-dialog-title">
        {(data || !isLoading) && (
          <Stack direction="row" alignItems="center" spacing={3}>
            <Box style={{ display: 'flex', gap: 20 }}>
              <Typography variant="h6">
                {data.is_archived && <Chip label="Archived" color="primary" />}
                <CaptionSC>Code</CaptionSC>
                {data?.code}
              </Typography>
              <Box sx={{ height: '100px' }}>
                <Divider orientation="vertical" light />
              </Box>
              <Typography variant="h6">
                {data.is_archived && <Box style={{ height: 32 }} />}
                <CaptionSC>Description</CaptionSC>
                {data?.description}
              </Typography>
            </Box>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent dividers sx={{ width: '100%' }}>
        {!data || isLoading ? (
          <CircularProgress />
        ) : (
          <Box>
            <AssociatedRubricSection size="100%">
              <FormSectionHeader>
                <FormSectionHeaderSubtitle>Notes</FormSectionHeaderSubtitle>
              </FormSectionHeader>

              <AssociatedRubricLayout>
                <Body1SC>{data?.associatedRubric?.notes}</Body1SC>
              </AssociatedRubricLayout>
            </AssociatedRubricSection>
            <AssociatedRubricSection size="100%">
              <FormSectionHeader>
                <FormSectionHeaderSubtitle>Rubrics</FormSectionHeaderSubtitle>
              </FormSectionHeader>
              <CaptionSC>Associated Rubric</CaptionSC>
              <AssociatedRubricLayout>
                <Body1SC>{data?.associatedRubric?.title}</Body1SC>
              </AssociatedRubricLayout>
              <CaptionSC>Microcompetency</CaptionSC>

              <Body1SC>
                {data?.associatedRubric?.microcompetency?.fullCode} -{' '}
                {data?.associatedRubric?.microcompetency?.title}
              </Body1SC>
              {data?.associatedRubric?.steps
                ?.sort((a: AssociatedRubricStep, b: AssociatedRubricStep) =>
                  sortByProperty(a, b, 'order'),
                )
                .map((step: any) => (
                  <AccordionRow
                    key={step.uuid}
                    step={step}
                    elevation={0}
                    shouldStartOpen
                  >
                    <>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Typography variant="caption">
                          <CaptionSC>Max Points</CaptionSC>
                          {data?.maxPoints && data?.maxPoints[step.uuid]}
                        </Typography>
                        <Box sx={{ height: '50px' }}>
                          <Divider orientation="vertical" light />
                        </Box>

                        <Typography variant="caption">{step?.title}</Typography>
                      </Stack>
                      {data?.checkSteps &&
                        data?.checkSteps[step.uuid] &&
                        data?.checkSteps[step.uuid]?.map((checkStep: any) => (
                          <Typography key={checkStep.uuid} variant="caption">
                            <CaptionSC>{`Checkstep ${checkStep?.order}`}</CaptionSC>
                            {checkStep?.name}
                          </Typography>
                        ))}
                    </>
                  </AccordionRow>
                ))}
            </AssociatedRubricSection>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodeModal;
