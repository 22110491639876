import { Grid, Typography } from '@mui/material';
import { ReactElement, SyntheticEvent,  useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  AssociatedRubricStep,
  Category,
  CheckStep,
} from '../../SearchBy/state/types';

import { Accordion, AccordionSummary, AccordionDetails } from './styled';

type AccordionRowProps = {
  shouldStartOpen?: boolean;
  toggleOpenRow?: () => void;
  children: ReactElement | ReactElement[];
  category?: Category;
  elevation: number;
  shouldAddElevation?: boolean;
  step?: AssociatedRubricStep;
  checkStep?: CheckStep;
};

export const AccordionRow = ({
  toggleOpenRow,
  children,
  category,
  step,
  checkStep,
  elevation,
  shouldAddElevation,
  shouldStartOpen = false,
}: AccordionRowProps) => {
  const themeContext = useContext(ThemeContext); 

  const [expanded, setExpanded] = useState(
    shouldStartOpen ? category?.uuid || step?.uuid : ''
  );

  const handleChange =
    (panel?: string) =>
    (event: SyntheticEvent<Element, Event>, newExpanded: boolean) => {
      if (panel) setExpanded(newExpanded ? panel : '');
    };

  if (step || checkStep) {
    return (
      <Grid
        item
        xs={12}
        md={12}
        style={{
          marginLeft: `${elevation}rem`,
          paddingTop: '0.5rem',
        }}
      >
        <Accordion
          shouldAddElevation={shouldAddElevation}
          // elevation={elevation}
          expanded={expanded === step?.uuid || expanded === checkStep?.uuid}
          onChange={handleChange(step?.uuid || checkStep?.uuid)}
          style={{ border: `1px solid ${themeContext.colors.black[12]}` }}
        >
          <AccordionSummary
            style={{
              borderBottom: `1px solid ${themeContext.colors.black[12]}`,
              backgroundColor: '#fafafa',
            }}
            aria-controls={step?.uuid || checkStep?.uuid}
            id={step?.uuid || checkStep?.uuid}
          >
            <Typography variant="body2">
              {step && `Rubric Step ${step?.order + 1}`}
              {checkStep && `Rubric CheckStep ${checkStep?.order + 1}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{
        marginLeft: `${elevation}rem`,
        paddingTop: '0.5rem',
      }}
    >
      <Accordion
        shouldAddElevation={shouldAddElevation}
        expanded={expanded === category?.uuid}
        onChange={handleChange(category?.uuid)}
        style={{ border: `1px solid ${themeContext.colors.black[12]}` }}
      >
        <AccordionSummary
          style={{
            borderBottom: `1px solid ${themeContext.colors.black[12]}`,
            backgroundColor: '#fafafa',
          }}
          aria-controls={category?.uuid}
          id={category?.uuid}
        >
          <Typography variant="body2">{category?.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AccordionRow;
