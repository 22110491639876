import {Link} from 'react-router-dom';
import {Typography} from '@mui/material';
import NavigationLogout from '../NavigationLogout/NavigationLogout';
import {AppBarSC, Brand, Image, Logo, SidebarToggle, ToolbarSC, ToolbarSection} from './styled';
import {useBreakPoint} from '../../../../helpers/hooks/useBreakPoint';
import logo from '../../../../assets/logo/logo.png';

type NavigationBarProps = {
  user: {
    firstName: string;
    lastName: string;
    institutionLogo?: string;
    error?: any;
    loading?: boolean;
  };
  toggleDrawer: () => void;
  onLogout: () => void;
  institutionName: string;
};

export default function NavigationBar({
  user,
  toggleDrawer,
  onLogout,
  institutionName,
}: NavigationBarProps) {
  const { isDesktop } = useBreakPoint();

  let institutionLogoUrl = logo;

  if (user.institutionLogo) {
    institutionLogoUrl = process.env.REACT_APP_USE_IBM_CLOUD === 'true' ? user.institutionLogo : `data:image/jpeg;base64,${user.institutionLogo}`;
  }

  return (
    <AppBarSC position="fixed">
      <ToolbarSC>
        <ToolbarSection>
          <SidebarToggle
            data-testid="navigation-toggle"
            onClick={toggleDrawer}
          />
          <Brand>
            <Logo>
              <Image
                isInstitution={!!user.institutionLogo}
                src={institutionLogoUrl}
                alt="Logo"
              />
            </Logo>

            {isDesktop && (
              <Link to="/">
                <Typography variant={!isDesktop ? 'button' : 'h6'}>
                  {institutionName ? institutionName : 'XCOMP Code Finder'}
                </Typography>
              </Link>
            )}
          </Brand>
        </ToolbarSection>
        <ToolbarSection>
          {isDesktop && (
            <Typography variant="body1" sx={{paddingRight: '1rem'}}>
              {user.loading 
                ? 'Loading...'
                : user.error 
                  ?'User Not Found' 
                  : `${user.firstName} ${user.lastName}`
              }
            </Typography>
          )}
          <NavigationLogout user={user} onLogout={onLogout}/>
        </ToolbarSection>
      </ToolbarSC>
    </AppBarSC>
  );
}
