import {
  SearchByDescription,
  SearchByCategory,
  SearchByCode,
} from '../SearchBy';

export const routes = [
  {
    path: 'school/:schoolUuid/search/by-description',
    component: <SearchByDescription />,
  },
  {
    path: 'school/:schoolUuid/search/by-category',

    component: <SearchByCategory />,
  },
  {
    path: 'school/:schoolUuid/search/by-code',
    component: <SearchByCode />,
  },
];

// export const routes = [
//   {
//     path: `school/:schoolUuid/search`,
//     component: <div />,
//     subroutes: [
//       {
//         path: 'by-description',
//         component: SearchByDescription,
//       },
//       {
//         path: 'by-category',

//         component: SearchByCategory,
//       },
//       {
//         path: 'by-code',
//         component: SearchByCode,
//       },
//     ],
//   },
// ];
