import { SearchResultsContainer } from '../components/SearchResultsContainer';
import {
  Loading,
  NoAssessmentsIllustratorDiv,
  NoAssessmentsMessageContainer,
} from './styled';
import { MousePointerIllustration } from '../../../helpers/svgIcons';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { DisplayProcedureCodesBy } from '../components/DisplayProcedureCodesBy';
import ClearAll from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment, useState } from 'react';
import { useBreakPoint } from '../../../helpers/hooks/useBreakPoint';
import { useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import fetch from '../../../helpers/fetch';
import { parseSearchResults } from '../hooks/useSearchByHelpers';
import { SearchByCode as SearchByCodeType } from '../state/types';
import CodeModal from '../components/CodeModal/CodeModal';

const SearchByCode = () => {
  const { isDesktop } = useBreakPoint();

  const params = useParams();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [type, setType] = useState<{
    value: string;
    label: string;
  } | null>({ value: 'task', label: 'Task' });
  const [selectedCode, setSelectedCode] = useState<SearchByCodeType | null>(
    null,
  );
  const [term, setTerm] = useState('');

  const {
    data: searchResults,
    trigger: triggerSearchByDescription,
    isMutating: searchIsMutating,
  } = useSWRMutation('/api/search/by-code', async (url, { arg }) => {
    const { search } = arg as {
      search: string;
    };

    const res = await fetch(
      `${url}/${params.schoolUuid}/${encodeURIComponent(search)}`,
    );

    const searchRes = (await res.json()) as {
      procedureCodes: SearchByCodeType[];
    };

    return parseSearchResults(searchRes.procedureCodes);
  });

  return (
    <SearchResultsContainer>
      <>
        <Box
          display="flex"
          flexDirection={isDesktop ? 'row' : 'column'}
          width="100%"
          gap={isDesktop ? 10 : 3}
        >
          <TextField
            id="description"
            sx={{ width: '100%' }}
            label="Search by code"
            variant="outlined"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // @ts-ignore
                triggerSearchByDescription({
                  // @ts-ignore
                  search: e?.target?.value as string,
                });
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                >
                  {term.length > 1 && (
                    <IconButton
                      aria-label="clear search term"
                      onClick={() => {
                        setTerm('');
                      }}
                      edge="end"
                    >
                      <ClearAll />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="search by description code"
                    onClick={() => {
                      // @ts-ignore
                      triggerSearchByDescription({
                        search: term,
                      });
                    }}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            id="combo-box-demo"
            sx={{ width: '100%' }}
            options={[
              {
                label: 'Appointment',
                value: 'appointment',
              },
              {
                label: 'Task',
                value: 'task',
              },
            ]}
            value={type}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, newValue) => {
              setType(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Type" />}
          />
        </Box>
        <Box marginY={4}>
          <Divider />
        </Box>
        {searchIsMutating ? (
          <Loading>
            <CircularProgress size={50} />
          </Loading>
        ) : (
          !searchResults?.length && (
            <NoAssessmentsMessageContainer>
              <NoAssessmentsIllustratorDiv>
                <MousePointerIllustration />
                <div>Please use the above search field</div>
              </NoAssessmentsIllustratorDiv>
            </NoAssessmentsMessageContainer>
          )
        )}

        {detailsModalOpen && selectedCode && (
          <CodeModal
            modalOpen={detailsModalOpen}
            toggleModal={() => {
              setDetailsModalOpen(!detailsModalOpen);
            }}
            codeUuid={selectedCode.uuid}
          />
        )}

        {!searchIsMutating && searchResults && (
          <DisplayProcedureCodesBy
            type={type?.value || 'task'}
            codes={searchResults || []}
            onRowClick={(code) => {
              setSelectedCode(code);
            }}
            toggleModal={() => setDetailsModalOpen(!detailsModalOpen)}
          />
        )}
      </>
    </SearchResultsContainer>
  );
};

export default SearchByCode;
