import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as JotaiProvider } from 'jotai';

import App from './components/App/App';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <JotaiProvider>
      <Suspense fallback="loading...">
        <App />
      </Suspense>
    </JotaiProvider>
  </React.StrictMode>
);
