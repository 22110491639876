import { ReactElement, useCallback, useEffect, useState } from 'react';

import { AccordionSummary } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import {
  ClosedDrawerIcon,
  DrawerHeaderTitle,
  SchoolHeaderLayout,
  StyledExpansionPanel,
  StyledListItemIcon,
} from './styled';
import { useLocation } from 'react-router-dom';
import { SchoolList } from '../UserSchoolsList/UserSchoolList';

type SchoolExpansionPanelProps = {
  children: ReactElement[];
  school: SchoolList;
  drawerOpen: boolean;
};

const SchoolExpansionPanel = ({
  children,
  school,
  drawerOpen,
}: SchoolExpansionPanelProps) => {
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(school.uuid)) {
      setExpanded(true);
    }
  }, [pathname, school.uuid]);

  const onChange = () => {
    setExpanded(!expanded);
  };

  const getIcon = useCallback(
    (expanded: boolean) => (expanded ? <ExpandMore /> : <ChevronRight />),
    []
  );

  return (
    <StyledExpansionPanel expanded={expanded}>
      <AccordionSummary onClick={onChange}>
        <SchoolHeaderLayout>
          <StyledListItemIcon>
            {drawerOpen ? (
              getIcon(expanded)
            ) : (
              <ClosedDrawerIcon>{getIcon(expanded)}</ClosedDrawerIcon>
            )}
          </StyledListItemIcon>

          {drawerOpen && <DrawerHeaderTitle>{school.name}</DrawerHeaderTitle>}
        </SchoolHeaderLayout>
      </AccordionSummary>
      {children}
    </StyledExpansionPanel>
  );
};

export default SchoolExpansionPanel;
