import { CircularProgress, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { ReactElement } from 'react';
import { useBreakPoint } from '../../../helpers/hooks/useBreakPoint';
import { WelcomeBack } from '../../../helpers/svgIcons';
import { userState } from '../../Navigation/state';

import { Container, Loading, Box, Spacer } from './styled';

const Welcome = (): ReactElement => {
  const [user] = useAtom(userState);
  const { isDesktop } = useBreakPoint();

  return (
    <Container>
      {user.loading ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : (
        <Box>
          <WelcomeBack />
          <Spacer />
          <Typography variant={isDesktop ? 'h3' : 'h6'}>
            Welcome back, {user.firstName}!
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Welcome;
