import Divider from '@mui/material/Divider';
import NavigationDrawer from '@mui/material/Drawer';
import { Box, CircularProgress } from '@mui/material';

import UserSchoolList from './components/UserSchoolsList/UserSchoolList';
import NavigationBar from './components/NavigationBar/NavigationBar';

import { useNavigationState } from './hooks/useNavigationState';
import { DrawerBody, Loading } from './styled';
import { useBreakPoint } from '../../helpers/hooks/useBreakPoint';

const Navigation = () => {
  const {
    drawerOpen,
    handleLogout,
    handleToggleDrawer,
    onSetSchoolFeature,
    user,
  } = useNavigationState();
  const { isDesktop } = useBreakPoint();

  return (
    <Box>
      <NavigationBar
        user={user}
        toggleDrawer={handleToggleDrawer}
        onLogout={handleLogout}
        institutionName={user?.institution || ''}
      />
      {(!user.error || user.loading) &&
        <NavigationDrawer
          variant={isDesktop ? 'permanent' : 'temporary'}
          open={drawerOpen}
          // anchor={isMobile || isTablet ? 'top' : 'left'}
          anchor="left"
          // PaperProps={isMobile || isTablet ? { style: { height: '100%' } } : {}}
        >
          <DrawerBody
            tabIndex={0}
            role="button"
            open={drawerOpen}
            loading={user.loading}
            // fullWidth={isMobile || isTablet}
          >
            {user.loading ? (
              <Loading>
                <CircularProgress />
              </Loading>
            ) : (
              <>
                <UserSchoolList
                  institution={user.institutions[0]}
                  schoolList={user.schools}
                  drawerOpen={drawerOpen}
                  onSetSchoolFeature={onSetSchoolFeature}
                />
                <Divider />
              </>
            )}
          </DrawerBody>
        </NavigationDrawer>
      }
    </Box>
  );
};

export default Navigation;
