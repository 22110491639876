import { SearchByCode } from '../state/types';

const getTaskName = (code: string) => {
  const codeFeatures = code.split('.')[1];
  if (!codeFeatures) return '';

  if (codeFeatures.toUpperCase().includes('C')) return 'Clinical';
  else if (codeFeatures.toUpperCase().includes('D')) return 'Digital';
  else if (codeFeatures.toUpperCase().includes('L')) return 'Laboratory';
  else if (codeFeatures.toUpperCase().includes('P')) return 'Presciption';
  else return 'Other';
};

const getAppointmentNumber = (code: string) =>
  parseInt(code.split('.')[1], 10)
    ? parseInt(code.split('.')[1], 10)
    : undefined;

export const parseSearchResults = (searchResults: SearchByCode[]) => {
  const parents = searchResults.filter((code) => !code.code.includes('.'));

  return parents.length > 0
    ? parents.map((code) => {
        const childCodes = searchResults
          .filter((childCode) => childCode.code.includes(`${code.code}.`))
          .map((child) => ({
            ...child,
            task: getTaskName(child.code),
            appointment: getAppointmentNumber(child.code),
          }));

        return {
          ...code,
          childrenCodes:
            childCodes.length > 0 ? [code, ...childCodes] : childCodes,
        };
      })
    : searchResults.map((item) => ({
        ...item,
        task: getTaskName(item.code),
        appointment: getAppointmentNumber(item.code),
      }));
};
