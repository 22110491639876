import styled, { css } from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';

export const Feature = styled(({ selected, isLevel2, open, ...rest }) => (
  <div {...rest} />
))`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding-left: ${({ isLevel2, open }: { isLevel2: boolean; open: boolean }) =>
    isLevel2 && open ? '49px' : '13px'};
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background: ${(props) =>
    props.selected ? props.theme.navigation.hover : 'none'};
  svg {
    fill: ${(props) => props.theme.colors.text.medium};
  }

  ${(props) =>
    props.selected &&
    css`
      border-left: 3px solid ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.navigation.hover};

      svg {
        fill: ${(props) => props.theme.colors.primary};
      }
      p {
        font-weight: bold;
        color: ${(props) => props.theme.colors.primary};
      }
    `}

  &:hover,
    &:focus,
    &:active {
    background: ${(props) => props.theme.navigation.hover};
    border-left: ${(props) => `3px solid ${props.theme.colors.primary}`};

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
    p {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const FeatureIcon = styled.div`
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: justify;
`;

export const FeatureLabel = styled(({ drawerOpen, ...rest }) => (
  <Body1 {...rest} />
))`
  margin-left: 18px;
  white-space: nowrap;
  width: 100%;
  color: ${(props) => props.theme.colors.text.medium};
  opacity: ${(props) => (props.drawerOpen ? '1' : '0')};
`;

export const Item = styled(({ drawerOpen, isCurrentPage, ...rest }) => (
  <div {...rest} />
))`
  && {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    padding-left: 15px;
    svg {
      fill: ${(props) => props.theme.colors.black[54]};
    }

    ${(props) =>
      props.isCurrentPage &&
      css`
        border-left: 3px solid ${(props) => props.theme.colors.primary};
        background-color: ${(props) => props.theme.colors.primaryShades[50]};

        svg {
          fill: ${(props) => props.theme.colors.primary};
        }
        p {
          font-weight: bold;
          color: ${(props) => props.theme.colors.primary};
        }
      `}

    &:hover,
    &:focus,
    &:active {
      border-left: 3px solid ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.colors.primaryShades[50]};

      svg {
        fill: ${(props) => props.theme.colors.primary};
      }
      p {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const ItemText = styled(({ drawerOpen, ...rest }) => (
  <Body1 {...rest} />
))`
  margin-left: 12px;
  opacity: ${(props) => (props.drawerOpen ? '1' : '0')};
  transition: opacity 0.4s ease-in;
  transition-delay: 0.2s;
`;

export const Icon = styled.div``;
