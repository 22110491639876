import styled from 'styled-components';

import { AccountCircle } from '@mui/icons-material';

export const LayoutSC = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconSC = styled(AccountCircle)`
  && {
    margin: 1rem;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const ProfileSC = styled.div`
  display: flex;
  flex-direction: column;

  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
`;

export const Name = styled.div`
  font-size: 1.25rem;
`;
