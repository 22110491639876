import { ReactElement } from 'react';
import { useAtom } from 'jotai';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useBreakPoint } from '../../../../helpers/hooks/useBreakPoint';
import { navigationState } from '../../../Navigation/state';
import { Main } from './styled';

type SearchResultsContainerProps = {
  children: ReactElement | ReactElement[];
};

const SearchContainer = ({ children }: SearchResultsContainerProps) => {
  const { isMobile, isTablet } = useBreakPoint();
  const [{ drawerOpen }] = useAtom(navigationState);
  if (isMobile || isTablet) {
    return (
      <Main open={drawerOpen} isMobile={true}>
        <Box sx={{ padding: '20px' }}>{children}</Box>
      </Main>
    );
  }

  return (
    <Main open={drawerOpen} isMobile={isMobile || isTablet}>
      <Paper
        sx={{
          padding: '30px 50px ',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
        elevation={4}
      >
        {children}
      </Paper>
    </Main>
  );
};

export default SearchContainer;
