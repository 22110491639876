import { AccountCircle } from '@mui/icons-material';
import { IconSC, LayoutSC, Name, ProfileSC } from './styled';

type NavigationProfileProps = {
  user: {
    firstName: string;
    lastName: string;
    error?: any;
    loading?: boolean;
  };
  handleClose?: () => void;
};
const NavigationProfile = ({ user }: NavigationProfileProps) => (
  <LayoutSC>
    <IconSC fontSize="large">
      <AccountCircle />
    </IconSC>
    <ProfileSC>
      <Name>
        {user.loading 
          ? 'Loading...'
          : user.error 
            ? 'User Not Found' 
            : `${user.firstName} ${user.lastName}`
        }
      </Name>
    </ProfileSC>
  </LayoutSC>
);

export default NavigationProfile;
